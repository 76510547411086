<template>
  <div class="login-container__wap">
    <img src="/static/images/position_match.png"
         style="width:100vw;">
    <div class="app-container">

      <div class="logo-container">

      </div>
      <!-- <h4 class="company-name">深圳市腾讯计算机系统有限公司</h4>
      <h2 class="evaluation-title">2019Q3新员工人岗匹配考核
        复评入口页面</h2> -->
      <el-form label-width="40px"
               class="wap-login-form">
        <el-form-item label="姓名">
          <el-input v-model="params.userName"
                    class="input__wap"
                    placeholder="请输入您的姓名"></el-input>
        </el-form-item>
        <el-form-item label="工号">
          <el-input v-model="params.jobNumber"
                    class="input__wap"
                    placeholder="请输入您的工号"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align:center;">
        <el-button class="login-btn"
                   @click="submit"
                   :loading="loading"
                   type="primary">开始评价</el-button>
      </div>

    </div>
  </div>
</template>
<script>
import { loginAndStartJudge, loginAndGetEvaluations } from '../../api/wap';
import { Notify } from 'vant';
import 'vant/lib/notify/style';
export default {
  data () {
    return {
      loading: false,
      params: {
        userName: '',
        jobNumber: '',
        scheduleId: '',
        assessorType: ''
      }
    };
  },
  mounted () {
    this.getDict();
    this.params.scheduleId = this.$route.query.scheduleId;
    this.params.assessorType = this.$route.query.assessorType;
    sessionStorage.setItem('wapLoginQuery', JSON.stringify({
      scheduleId: this.params.scheduleId,
      assessorType: this.params.assessorType
    }));
  },
  methods: {
    getDict () {
      this.$store.dispatch('app/getDict');
    },
    async login (type) {
      if (!this.params.userName || !this.params.jobNumber) {
        Notify({
          type: 'danger',
          message: '请填写您的姓名和工号'
        });
        return;
      }
      if (type === 'self') {
        loginAndStartJudge(this.params).then((res) => {
          if (res._responseStatusCode === 0) {
            sessionStorage.setItem('selfJudgeWapLoginInfo', JSON.stringify(this.params));
            sessionStorage.setItem('customerComId', res.customerComId);
            this.$router.push({ path: `/wap/judge/${type}` });
          }
        });
      } else if (type === 'again') {
        loginAndGetEvaluations(this.params).then((res) => {
          if (res._responseStatusCode === 0) {
            sessionStorage.setItem('againJudgeWapLoginInfo', JSON.stringify(this.params));
            sessionStorage.setItem('customerComId', res.customerComId);
            this.$router.push({ path: '/wap/evaluations', query: this.$route.query });
          }
        });
      }
    },
    submit () {
      this.login(this.$route.params.type);
    }
  }
};
</script>
<style lang="scss">
.input__wap input {
  line-height: 20px;
}
.el-form {
  .el-form-item__label {
    // color: #fff;
    white-space: nowrap;
  }
}
</style>
<style scoped lang="scss">
.login-container__wap {
  width: 100%;
  height: 100%;
  // background: url("/loginBg2.png") no-repeat center center fixed;
  // background-size: cover;
  .logo-container {
    text-align: center;
    margin-bottom: 40px;
  }
  .company-name {
    font-size: 18px;
    color: #606266;
    text-align: center;
  }

  .evaluation-title {
    color: #606266;
    margin: 10px 0 20px;
    text-align: center;
  }
  .login-btn {
    width: 50%;
    margin: 0 auto;
  }
  .app-container {
    padding: 0;
    min-width: 320px;
    padding: 20px;
    height: 410px;
    margin: auto;
    // position: absolute;
    left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    background: none;
    .backgroundBox {
      width: 60%;
      margin: 40px 0 40px 40px;
      display: inline-block;
      img {
        width: 100%;
      }
    }
  }
}
</style>
